import React from "react";
import styles from "./InteractionLayer.module.scss";
import {useDrag} from "react-use-gesture";
import KeyboardEventHandler from 'react-keyboard-event-handler';
import {Stage} from "../atoms/Stage";


interface IProps {
    nextSlide: () => void,
    prevSlide: () => void,
}

export const InteractionLayer: React.FC<IProps> = ({nextSlide, prevSlide}) => {


    const handlePrev = () => {
        prevSlide()
    }

    const handleNext = () => {
        nextSlide()
    }


    const bind = useDrag(({active, distance, xy, event, last, cancel, direction, touches}) => {
            const considerDrag = distance > Math.min(50, window.innerWidth / 2);

            if (touches > 1) {
                cancel()
            } else if (active && considerDrag) {
                // handle as darag
                const [dx] = direction;
                if (dx > 0) {
                    handlePrev()
                } else {
                    handleNext()
                }
                cancel()
            } else if (last && !considerDrag) {
                // handle as click
                const [x] = xy
                if (x < window.innerWidth / 2) {
                    handlePrev()
                } else {
                    handleNext()
                }
            }
        }
    )


    const handleKey = (key: string, e: KeyboardEvent) => {
        if (key === "left" || key === "up") {
            handlePrev()
        }
        if (key === "right" || key === "down") {
            handleNext()
        }
    }

    return (
        <Stage type={"interaction"}>
            <div
                className={styles.wrapper}
                {...bind()}
            >

                <KeyboardEventHandler
                    handleKeys={["left", "up", "right", "down"]}
                    onKeyEvent={handleKey}
                />

                <div className={styles.prev} />
                <div className={styles.next}/>

            </div>
        </Stage>
    )
}