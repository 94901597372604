import React from "react";
import styles from "./Redactor.module.scss";


export const Redactor: React.FC<{
    html: string | null
}> = ({
          html
      }) => {
    if (html) {
        return <div
            dangerouslySetInnerHTML={{__html: html}}
            className={styles.redactor}
        />
    } else {
        return null;
    }
}
