import styles from "./NavBtn.module.scss"
import React, {MouseEventHandler} from "react";
import {ContrastObserver} from "./ContrastObserver";

export const NavBtn: React.FC<{
    active: boolean,
    onClick: MouseEventHandler<HTMLButtonElement>
    onMouseEnter: MouseEventHandler<HTMLButtonElement>
}> = ({
          active,
          onClick,
          onMouseEnter
      }) => {
    return (
        <button
            type="button"
            className={`${styles.button} ${active ? styles.active : ""}`}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
        >
            <ContrastObserver>

                <svg
                    className={styles.svg}
                    viewBox="0 0 380 180"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    vectorEffect="non-scaling-stroke"
                >
                    <line
                        className={`${styles.line} ${styles.top}`}
                        x1="0" y1="0" x2="380" y2="0"
                        stroke="currentColor"
                        strokeWidth="2px"
                    />
                    <line
                        className={`${styles.line} ${styles.center}`}
                        x1="0" y1="90" x2="380" y2="90"
                        stroke="currentColor"
                        strokeWidth="2px"
                    />
                    <line
                        className={`${styles.line} ${styles.bottom}`}
                        x1="0" y1="180" x2="380" y2="180"
                        stroke="currentColor"
                        strokeWidth="2px"
                    />
                </svg>
            </ContrastObserver>

        </button>
    )
}