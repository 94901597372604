import styles from "./Stage.module.scss"

export const Stage: React.FC<{
    type: "slide" | "intro" | "nav-btn" | "nav-body" | "info-btn" | "interaction" | "about",
}> = ({children, type}) => {
    return (
        <div className={`
            ${styles.stage}
            ${styles[type]}
        `}>
            {children}
        </div>
    )
}