import React from "react";
import {ITextBlock} from "../../types/types";
import {Redactor} from "./Redactor";
import styles from "./TextBlock.module.scss"

export const TextBlock: React.FC<{
    block: ITextBlock
}> = ({
          block
      }) => {
    return (
        <div className={styles.text}>
            {block.headline &&
                <h3>{block.headline}</h3>
            }
            <Redactor html={block.text}/>
        </div>
    )
}
