import React, {useContext} from "react";
import {BackgroundColorContext} from "../providers/BackgroundProvider";
import styles from "./ContrastObserver.module.scss"

export const ContrastObserver: React.FC
    = ({
           children
       }) => {

    const {isDark} = useContext(BackgroundColorContext);

    return (
        <div className={isDark ? styles.bright : styles.dark}>
            {children}
        </div>
    )
}