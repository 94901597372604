import React, {Fragment} from "react";
import {IImageGroup} from "../../types/types";
import arrayShuffle from "array-shuffle";
import {SlideshowController} from "../organisms/SlideshowController";
import {SlideWrapper} from "../organisms/SlideWrapper";
import {AppContext} from "../providers/AppContext";
import {InfoContext} from "../providers/InfoProvider";
import {insertTokenEveryN} from "../../utils";
import {CopyrightSlide} from "../atoms/CopyrightSlide";

type TCursor = [
    number, // index of order
    number // index of image in selected group
]

interface IProps {
    active: boolean,
    groups: IImageGroup[],
    showCopyright?: boolean,
}

interface IState {
    cursor: TCursor,
    order: (number | "copyright")[],
}

const buildOrder = (groups: IImageGroup[], showCopyright: boolean) => {
    const shuffled = arrayShuffle(groups.map((group, i) => i)) // array of the group indexes shuffled
    if(showCopyright){
        return insertTokenEveryN(shuffled, "copyright", 14)
    }else{
        return shuffled
    }
}

export class MediaSection extends React.Component<IProps, IState> {
    private timer: any;
    static contextTyp = AppContext;

    constructor(props: IProps) {
        super(props);
        const {groups, showCopyright} = this.props;
        this.state = {
            cursor: [0, 0],
            order: buildOrder(groups, !!showCopyright),
        }
        //console.log(this.state.order)
        this.resetSlideshow = this.resetSlideshow.bind(this)
        this.next = this.next.bind(this)
        this.prev = this.prev.bind(this)
        this.getNextCursor = this.getNextCursor.bind(this)
        this.getPrevCursor = this.getPrevCursor.bind(this)
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        // slideshow becomes inactive
        // start shuffle timer
        if (prevProps.active && !this.props.active) {
            this.timer = window.setTimeout(this.resetSlideshow, 1200)
        }
        if (this.props.active) {
            // if this section becomes active again make sure it wont shuffle
            window.clearTimeout(this.timer)
        }
    }

    resetSlideshow() {
        const {groups, showCopyright} = this.props;
        this.setState({
            cursor: [0, 0],
            order: buildOrder(groups, !!showCopyright),
        })
    }

    next() {
        this.setState({
            cursor: this.getNextCursor(),
        })
    }

    prev() {
        this.setState({
            cursor: this.getPrevCursor(),
        })
    }

    getNextCursor() {
        const {cursor, order} = this.state;
        const {groups} = this.props;

        let nextCursor: TCursor = [0, 0];
        let selectedGroup = order[cursor[0]];

        /****
         *      Either the selected group is a copyright, or it is an Image Array.
         *      If it is a copyright, increase the group index and set the image index to 0.
         *
         *      If it is an Image Slide, try to increase the image index.
         *      if thats not possible try to increase the group index. If either fails leave the value to zero
         ***/

        if (selectedGroup === "copyright") {
            // if current group cursor points to copyright, increase the group.
            // Leave img index to 0.
            if (
                cursor[0] + 1 // next order index
                <= // is smaller or equal
                order.length - 1 // last order index
            ) {
                nextCursor[0] = cursor[0] + 1
            }
        } else {

            if (
                cursor[1] + 1 // next img index
                <= // is smaller or equal
                groups[selectedGroup].images.length - 1 // last image index
            ) {
                nextCursor = [cursor[0], cursor[1] + 1]
                // if not possible, try to increase group index
            } else if (
                cursor[0] + 1 // next order index
                <= // is smaller or equal
                order.length - 1 // last order index
            ) {
                nextCursor[0] = cursor[0] + 1
            }

        }
        // else the cursor will still be [0,0] and therefore jump to first image in first group


        return nextCursor;
    }

    getPrevCursor() {
        const {cursor, order} = this.state;
        const {groups} = this.props;

        let nextCursor: TCursor = [0, 0];

        // try to decrease image index
        if (
            cursor[1] - 1 // decreased img index
            >= // is greater or equal
            0 // than zero
        ) {
            nextCursor = [cursor[0], cursor[1] - 1]
            // if not possible, try to decrease group index
            // and chose the last image of this group
        } else if (
            cursor[0] - 1 // decreased order index
            >= // is greater or equal
            0 // than zero
        ) {
            // if next is copyright do not decrease the img cursor
            const nextGroup = order[cursor[0] - 1];
            let nextImage = 0;
            if (nextGroup !== "copyright") {
                nextImage = groups[nextGroup].images.length - 1 // last image index of image in selected group
            }
            nextCursor = [
                cursor[0] - 1, // decreased order index
                nextImage
            ]
        } else {
            // jump to last order and last image in the selected group
            // if next is copyright do not decrease the img cursor
            const nextGroup = order[order.length - 1];
            let nextImage = 0;
            if (nextGroup !== "copyright") {
                nextImage = groups[nextGroup].images.length - 1  // last image index of image in selected group
            }
            nextCursor = [
                order.length - 1,   // last possible order index
                nextImage           // last possible image index in selected group
            ]
        }
        return nextCursor;
    }

    render() {
        const {cursor, order} = this.state;
        const {groups, active} = this.props;

        const nextCursor = this.getNextCursor();
        const prevCursor = this.getPrevCursor();

        // const currentSlideHasInfo = (groups[cursor[0]].info !== null);
        // console.log(cursor, prevCursor, nextCursor, order, groups)

        return (
            <AppContext.Consumer>

                {(context) =>
                    <Fragment>
                        {
                            // if this section is active
                            // render a control layer with the next prev controls
                            // and app context (the app context is here to make it available in the prevProps)
                            // if the current group cursor has an info, render the info button
                            active &&
                            <InfoContext.Consumer>
                                {infoContext =>
                                    <SlideshowController
                                        active={active}
                                        nextSlide={this.next}
                                        prevSlide={this.prev}
                                        {...context}
                                        {...infoContext}
                                    />
                                }
                            </InfoContext.Consumer>
                        }
                        {
                            groups.map((group, gi) => group.images.map((image, ii) => {

                                // the slide is active
                                // if the section is active AND the group is active AND the image is active
                                const isActive = active && (gi === order[cursor[0]]) && (ii === cursor[1]);

                                let shouldPreload: boolean;
                                if (active) {
                                    shouldPreload = ((gi === order[nextCursor[0]]) && (ii === nextCursor[1])) || ((gi === order[prevCursor[0]]) && (ii === prevCursor[1]));
                                } else {
                                    shouldPreload = ((gi === order[cursor[0]]) && (ii === cursor[1]));
                                }

                                return (
                                    <InfoContext.Consumer key={group.id + "_" + image.id}>
                                        {
                                            // inject the infoContext into the slide
                                            infoContext =>
                                                <SlideWrapper
                                                    key={gi.toString() + image.id}
                                                    image={image}
                                                    group={group}
                                                    shouldPreload={shouldPreload}
                                                    isActive={isActive}
                                                    infoContext={infoContext}
                                                />
                                        }
                                    </InfoContext.Consumer>
                                )

                            }))
                        }

                        <CopyrightSlide visible={(active && order[cursor[0]] === "copyright")}/>

                    </Fragment>
                }
            </AppContext.Consumer>
        )
    }

}