import React from "react";
import {ITableBlock} from "../../types/types";

import styles from "./TableBlock.module.scss"

export const TableBlock: React.FC<{
    block: ITableBlock
}> = ({block}) => {

    return (
        <div className={styles.table}>

            {block.headline &&
                <h3>{block.headline}</h3>
            }

            {block.table.map(
                ({jahr, inhalt},index) => (
                    <div className={styles.row} key={index}>
                        <div className={styles.jahr}>{jahr}</div>
                        <div className={styles.inhalt}>{inhalt}</div>
                    </div>
                ))
            }
        </div>
    )
}
