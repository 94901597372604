import React, {useContext, useRef} from "react";
import {Redirect, Route, Switch,} from "react-router-dom";
import {About} from "./About";
import {AppContext} from "../providers/AppContext";
import {CSSTransition} from "react-transition-group";
import {Navigation} from "../organisms/Navigation";
import {MediaSection} from "./MediaSection";
import {InfoProvider} from "../providers/InfoProvider";
import {InfoBtn} from "../atoms/InfoBtn";

const mediaSections = [
    {type: "architektur", showCopyright: true},
    {type: "flora", showCopyright: true},
    {type: "publikationen", showCopyright: false},
] as { type: "architektur" | "flora" | "publikationen", showCopyright: boolean }[];

export const Routing: React.FC = props => {

    const {data} = useContext(AppContext);
    const aboutRef = useRef(null);

    if (data) {
        return (
            <React.Fragment>

                <Navigation/>

                <InfoProvider>
                    <Switch>

                        <Route
                            path="/cv-kontakt"
                            children={({match}) =>
                                <CSSTransition
                                    in={!!match}
                                    timeout={1000}
                                    classNames={"fade-1000"}
                                    unmountOnExit
                                    mountOnEnter
                                    nodeRef={aboutRef}
                                >
                                    <div ref={aboutRef}>
                                        <About
                                            about={data?.about}
                                            visible={!!match}
                                        />
                                    </div>
                                </CSSTransition>
                            }
                        />

                        {
                            mediaSections.map(
                                section =>
                                    <Route
                                        key={section.type}
                                        path={"/" + section.type}
                                        children={({match, location}) =>
                                            <MediaSection
                                                active={!!match}
                                                groups={data[section.type]}
                                                showCopyright={section.showCopyright}
                                            />
                                        }
                                    />
                            )
                        }

                        <Route>
                            <Redirect to="/architektur"/>
                        </Route>

                    </Switch>

                    <InfoBtn/>


                </InfoProvider>


            </React.Fragment>
        )
    } else {
        return null;
    }

}