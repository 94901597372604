import {IData} from "../../types/types";
import React from "react";

export interface IAppContext {
    data: IData | null,
    error: null|string,
    intro: boolean,
    info: string | null,
    setInfo: (info:string | null)=>void,
    nav: boolean,
    setNav: (bool:boolean)=>void,
}

export const defaultAppContext: IAppContext = {
    data: null,
    error: null,
    intro: true,
    info: null,
    setInfo: (info)=>{},
    nav: false,
    setNav: (bool)=>{},
}

export const AppContext = React.createContext<IAppContext>(defaultAppContext)