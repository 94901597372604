import React, {useContext, useEffect} from "react";
import {IAbout} from "../../types/types";
import {TextBlock} from "../atoms/TextBlock";
import {TableBlock} from "../atoms/TableBlock";
import styles from "./About.module.scss"
import {Redactor} from "../atoms/Redactor";
import {BackgroundColorContext} from "../providers/BackgroundProvider";
import {InfoContext} from "../providers/InfoProvider";


export const About: React.FC<{
    about: IAbout,
    visible: boolean,
}> = ({ about,visible }) => {


    const {setBackgroundColor} = useContext(BackgroundColorContext);
    useEffect(() => {
        setBackgroundColor("#ffffff");
        document.body.classList.remove("fixed");
        return () => {
            document.body.classList.add("fixed");
        }
    }, [])


    const {setHasInfo,setShowInfo} = useContext(InfoContext);
    useEffect(()=>{
        // if this becomes visible, then turn off info
        if(visible){
            //console.log("trun off info-btn")
            setHasInfo(false);
            setShowInfo(false);
        }
    },[visible])


    return (
        <div className={styles.container}>

            <div className={styles.kontakt}>

                <div className={styles.block}>
                    {
                        about.images.map(
                            (image,i) => <img
                                key={`${i}${image.id}`}
                                src={image.url_sm}
                                alt={image.title}
                                width={image.width}
                                height={image.height}
                                className={styles.image}
                            />
                        )
                    }
                </div>

                <div className={styles.block}>
                    <Redactor html={about.kontakt}/>
                </div>

            </div>

            <div className={styles.cv}>
                {
                    about.textMatrix.map(block => {
                            switch (block.type) {
                                case "fliesstextBlock":
                                    return (
                                        <div key={block.id} className={styles.block}>
                                            <TextBlock block={block}/>
                                        </div>
                                    )
                                case "tabelleBlock":
                                    return (
                                        <div key={block.id} className={styles.block}>
                                            <TableBlock block={block}/>
                                        </div>
                                    )
                            }
                        }
                    )
                }
            </div>

        </div>
    )
}