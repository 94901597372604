import React from "react";
import styles from "./Info.module.scss";
import {ContrastObserver} from "../atoms/ContrastObserver";


export const Info: React.FC<{
    text: string | null,
    active?: boolean,
}> = ({text, active}) => {

    if (text) {
        return (
            <div className={`${styles.container} ${active ? styles.active : ""}`}>
                <div className={styles.inner}>

                    <ContrastObserver>
                        <div className={styles.stage}>
                            {text}
                        </div>
                    </ContrastObserver>

                </div>
            </div>
        )
    } else {
        return null;
    }

}