import React from "react";
import {IImage} from "../../types/types";
import styles from "./Image.module.scss";
import {ScreenContext} from "../providers/ScreenProvider";


interface IProps {
    image: IImage,
    active?: boolean,
}

interface IState {
    loaded: boolean,
}


export class Image extends React.Component<IProps, IState> {

    private timer: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            loaded: false,
        }
        this.handleLoad = this.handleLoad.bind(this)
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {

        // if this slide becomes active
        if (!prevProps.active && this.props.active) {
        }
        // if this slide becomes inactive
        if (prevProps.active && !this.props.active) {
        }
    }

    handleLoad() {
        this.setState({
            loaded: true,
        })
    }

    render() {
        const {loaded} = this.state;
        const {image, active} = this.props;
        return (
            <div
                className={`
                ${styles.wrapper} 
                ${loaded ? styles.wrapperLoaded : ""} 
                ${active ? styles.withTransition : ""}
             `}
            >
                <ScreenContext.Consumer>{({screen}) => {

                    let src: "url_md" | "url_sm" | "url_lg" = "url_md";
                    if (screen === "sm") {
                        src = "url_sm"
                    } else if (screen === "md") {
                        src = "url_md"
                    } else if (screen === "lg" || screen === "xl" || screen === "xxl") {
                        src = "url_lg"
                    }

                    // change src
                    src = "url_lg";

                    return (
                        <img
                            src={image[src]}
                            alt={image.title}
                            onLoad={this.handleLoad}
                            width={image.width}
                            height={image.height}
                            className={`
                                ${styles.image} 
                                ${loaded ? styles.loaded : ""}  
                                ${active ? styles.withTransition : ""}
                            `}
                        />
                    )

                }}</ScreenContext.Consumer>

            </div>
        )
    }

}