import React, {useContext} from "react";
import {NavigationLink} from "../atoms/NavigationLink";
import styles from "./NavigationBody.module.scss";
import {BackgroundColorContext} from "../providers/BackgroundProvider";


export const NavigationBody: React.FC = props => {

    const {color} = useContext(BackgroundColorContext);
    const style = {
        backgroundColor: color,
    }

    return (
        <div
            className={styles.body}
            style={style}
        >
            <NavigationLink to={"/architektur"}>Architektur </NavigationLink>
            <NavigationLink to={"/flora"}>Flora </NavigationLink>
            <NavigationLink to={"/publikationen"}>Publikationen </NavigationLink>
            <NavigationLink to={"/cv-kontakt"}>CV Kontakt</NavigationLink>
        </div>
    )
}