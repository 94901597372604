import {IImage, IImageGroup} from "../../types/types";
import React from "react";
import {Slide} from "./Slide";
import {IInfoContext, InfoContext} from "../providers/InfoProvider";

interface IProps {
    image: IImage,
    isActive: boolean,
    shouldPreload: boolean,
    group: IImageGroup,
    infoContext: IInfoContext,
}

interface IState {
    keep: boolean,
}

export class SlideWrapper extends React.Component<IProps, IState> {
    private timer: any;

    constructor(props: IProps) {
        super(props);
        this.stopKeeping = this.stopKeeping.bind(this);
        this.state = {
            keep: this.props.isActive,
        }
    }

    componentDidMount() {
        if(this.props.isActive){
            // adjust the info btn
            this.props.infoContext.setHasInfo(!!this.props.group.info)
            this.props.infoContext.setShowInfo(false);
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {

        // this slide becomes active
        if (!prevProps.isActive && this.props.isActive) {
            clearTimeout(this.timer);
            this.setState({keep: true})

            // adjust the info btn
            this.props.infoContext.setHasInfo(!!this.props.group.info)
            this.props.infoContext.setShowInfo(false)

        }

        // this slide becomes inactive
        if (prevProps.isActive && !this.props.isActive) {
            this.timer = window.setTimeout(this.stopKeeping, 1000);
        }

    }

    stopKeeping() {
        this.setState({keep: false})
    }

    render() {

        const {isActive, shouldPreload} = this.props
        const {keep} = this.state
        const mounted = (isActive || shouldPreload || keep)

        if (mounted) {
            return <Slide {...this.props} />
        } else {
            return null
        }

    }
}