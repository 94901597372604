import React, {createContext, useEffect, useState} from "react";
import convert from "color-convert";

interface IContext {
    setBackgroundColor: (color: string | null) => void,
    isDark: boolean,
    color: string,
}

const defaultColor = "#C1C0C0";

const defaultContext = {
    setBackgroundColor: (color: string | null) => {
    },
    isDark: false,
    color: defaultColor,
}


export const BackgroundColorContext = createContext<IContext>(defaultContext);


export const BackgroundColorProvider: React.FC = (
    {children}
) => {

    const [color, _setColor] = useState<string | null>(null);
    const [isDark, _setIsDark] = useState<boolean>(false);

    useEffect(() => {
        document.body.style.background = color ? color : defaultColor;
        document.documentElement.style.background = color ? color : defaultColor;
    }, [color])

    const setBackgroundColor = (color: string | null) => {
        _setColor(color)
        if (color?.length) {
            const luminosity = convert.hex.hsl(color)[2];
            _setIsDark(luminosity <= 90)
        } else {
            _setIsDark(false)
        }
    }

    return (
        <BackgroundColorContext.Provider
            value={{
                setBackgroundColor,
                isDark,
                color: color ? color : defaultColor,
            }}
        >
            {children}
        </BackgroundColorContext.Provider>
    )
}