import React, {useContext, useEffect} from "react"
import styles from "./Slide.module.scss"
import {IImage, IImageGroup} from "../../types/types";
import {RatioContainer} from "../atoms/RatioContainer";
import {InfoContext} from "../providers/InfoProvider";
import {Image} from "../atoms/Image";
import {BackgroundColorContext} from "../providers/BackgroundProvider";
import {Stage} from "../atoms/Stage";
import {Info} from "./Info";


export const Slide: React.FC<{
    image: IImage,
    isActive: boolean,
    shouldPreload: boolean,
    group: IImageGroup,
}> = ({image, group, isActive, shouldPreload}) => {


    const {showInfo} = useContext(InfoContext);
    const {setBackgroundColor} = useContext(BackgroundColorContext);

    /*
    *   if slide becomes active
    *   add background color if is active
    *   inject the info into the info provider.
    */
    useEffect(() => {
        if (isActive) {
            setBackgroundColor(image.backgroundColor)
        }
    }, [isActive])

    //  determine factor
    let factor = undefined
    if (group.section === "publikationen") {
        factor = 0.9
    }

    return (
        <Stage type={"slide"}>
            <div className={`${styles.wrapper} ${isActive ? styles.isActive : ""}`}>

                <div className={styles.container}>
                    <RatioContainer
                        ratio={image.height / image.width}
                        factorTransform={image.groessenFaktor}
                        factor={factor}
                        key={image.id}
                    >
                        <Image
                            image={image}
                            active={isActive}
                        />
                    </RatioContainer>
                </div>

                <Info
                    text={group.info}
                    active={showInfo && isActive}
                />

            </div>
        </Stage>
    )
}