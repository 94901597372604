import {IData, TScreen} from "./types/types";


export const insertTokenEveryN = (arr: any[], token: "copyright", n: number, fromEnd?: undefined | boolean) => {

    // Clone the received array, so we don't mutate the
    // original one. You can ignore this if you don't mind.

    let a = arr.slice(0);

    // Insert the <token> every <n> elements.

    let idx = fromEnd ? a.length - n : n;

    while ((fromEnd ? idx >= 1 : idx <= a.length)) {
        a.splice(idx, 0, token);
        idx = (fromEnd ? idx - n : idx + n + 1);
    }

    return a;
}


export const defineBreakpoint = (): TScreen => {
    if (!window.innerWidth) {
        return "lg"
    } else if (window.innerWidth > 1600) {
        return "xxl"
    } else if (window.innerWidth > 1200) {
        return "xl"
    } else if (window.innerWidth > 992) {
        return "lg"
    } else if (window.innerWidth > 768) {
        return "md"
    } else {
        return "sm"
    }
}


export const buildRunningMeta = (data: IData) => {

    const total_arch = data.architektur.reduce((count, group) => count + group.images.length, 0);
    const total_flora = data.flora.reduce((count, group) => count + group.images.length, 0);
    const total_publ = data.publikationen.reduce((count, group) => count + group.images.length, 0);

    let index = 0;

    return {
        architektur: {
            total: total_arch,
            first: 0,
            last: total_arch - 1,
            cursor: -1,
            groupOrder: data.architektur.map((group, gi) => group.images.map((img, ii) => index++)).filter(arr => !!arr.length)
        },
        flora: {
            total: total_flora,
            first: total_arch,
            last: total_arch + total_flora - 1,
            cursor: -1,
            groupOrder: data.flora.map((group, gi) => group.images.map((img, ii) => index++)).filter(arr => !!arr.length)
        },
        publikationen: {
            total: total_publ,
            first: total_arch + total_flora,
            last: total_arch + total_flora + total_publ - 1,
            cursor: -1,
            groupOrder: data.publikationen.map((group, gi) => group.images.map((img, ii) => index++)).filter(arr => !!arr.length)
        },
    }
}