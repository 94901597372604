import React from "react";
import styles from "./Intro.module.scss"
import {CSSTransition} from "react-transition-group";
import {Stage} from "./Stage";


export const Intro: React.FC<{
    visible: boolean
}> = ({visible}) => {

    const nodeRef = React.useRef(null)

    return (
        <CSSTransition
            in={visible}
            timeout={1000}
            classNames={"fade-1000"}
            unmountOnExit
            mountOnEnter
            appear
            nodeRef={nodeRef}
        >
            <Stage type="intro">
                <div className={styles.intro} ref={nodeRef}>
                    Ruedi Walti<br/>
                    Fotografie
                </div>
            </Stage>
        </CSSTransition>

    )
}
