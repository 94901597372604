import React from "react";
import {AppContext, defaultAppContext, IAppContext} from "./AppContext";
import {Intro} from "../atoms/Intro";
import {Stage} from "../atoms/Stage";
import {autorunTimeout} from "../../constants";

interface IState extends IAppContext {
    keepIntro: boolean,
}

export class AppProvider extends React.Component<any, IState> {

    private timer: any;

    constructor(props: any) {
        super(props);
        this.state = {
            ...defaultAppContext,
            keepIntro: true,
        };
        this.stopKeepingIntro = this.stopKeepingIntro.bind(this);
        this.setNav = this.setNav.bind(this);
        this.setInfo = this.setInfo.bind(this);
    }

    componentDidMount() {
        fetch("/api/data.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        data: result,
                        intro: false,
                    });
                },
                (error) => {
                    this.setState({
                        error: error,
                        intro: false,
                    });
                }
            )
        this.timer = setTimeout(this.stopKeepingIntro, autorunTimeout);
    }

    stopKeepingIntro() {
        this.setState({
            keepIntro: false,
        })
    }

    setNav(bool: boolean) {
        this.setState({nav: bool});
    }

    setInfo(info: string | null) {
        this.setState({info});
    }

    render() {
        const {data, error, keepIntro, info, nav, intro} = this.state;

        // if there are errors log them into the console
        if (error) {
            console.log(error);
            return (
                <Stage type="intro">
                    Die Verbindung zum Server konnte nicht hergestellt werden. Bitte versuchen Sie es später erneut.
                </Stage>
            )
            // if there ain't errors render the app with intro
        } else {
            return (
                <React.Fragment>

                    <Intro
                        visible={(!data || keepIntro)}
                    />

                    {
                        data &&
                        <AppContext.Provider
                            value={{
                                data,
                                error,
                                intro: (intro || keepIntro),
                                info,
                                setInfo: this.setInfo,
                                nav,
                                setNav: this.setNav,
                            }}
                        >
                            {this.props.children}
                        </AppContext.Provider>
                    }
                </React.Fragment>
            )

        }

    }

}