import React, {useContext} from "react";
import styles from "./RatioContainer.module.scss"
import {withContentRect} from "react-measure";
import {ScreenContext} from "../providers/ScreenProvider";


export const RatioContainer = withContentRect('bounds')<{
    ratio: number,
    factor?: number,
    factorTransform: number,
}>(
    ({
         measureRef,
         measure,
         contentRect,
         children,
         ratio,
         factor,
         factorTransform
     }) => {

        const {screen} = useContext(ScreenContext);

        let imageWidth = 0;
        let f = factor ? factor : 0.59;

        if (screen !== "sm" && screen !== "md") {
            f *= (factorTransform / 100)
        }

        if (contentRect.bounds) {
            let boundingWidth = contentRect.bounds.width;
            let boundingHeight = contentRect.bounds.height;

            boundingHeight = boundingWidth = Math.min(contentRect.bounds.width, contentRect.bounds.height)

            imageWidth = Math.sqrt((
                (f * boundingWidth * boundingHeight) / ratio
            ));
            /*
            if (imageWidth > boundingWidth) {
                imageWidth = boundingWidth;
            }
            if (imageWidth * ratio > boundingHeight) {
                imageWidth = boundingHeight / ratio;
            }
            */
        }

        const imageStyle = {
            width: `${imageWidth}px`,
            height: `${imageWidth * ratio}px`,
        }

        return (
            <div
                className={styles.stage}
                ref={measureRef}
            >
                {
                    contentRect.bounds?.width &&
                    <div className={styles.inner} style={imageStyle}>
                        {children}
                    </div>
                }
            </div>
        )
    }
)