import React, {createContext, useEffect, useState} from "react";
import {defineBreakpoint} from "../../utils";
import {TScreen} from "../../types/types";





interface IContext {
    screen: TScreen,
}

const defaultContext = {
    screen: defineBreakpoint()
}


export const ScreenContext = createContext<IContext>(defaultContext);

export const ScreenProvider: React.FC =
    ({
         children
     }) => {

        const [screen, setScreen] = useState<TScreen>(defineBreakpoint());

        useEffect(() => {
            window.addEventListener("resize", ()=> {
                setScreen(defineBreakpoint())
            })
            return window.removeEventListener("resize", ()=> {
                setScreen(defineBreakpoint())
            })
        }, [])

        return (
            <ScreenContext.Provider
                value={{
                    screen,
                }}
            >
                {children}
            </ScreenContext.Provider>
        )
    }