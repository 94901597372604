import React from "react";
import {InteractionLayer} from "./InteractionLayer";
import {IAppContext} from "../providers/AppContext";
import {autorunTimeout} from "../../constants";
import {IInfoContext} from "../providers/InfoProvider";

interface IProps extends IInfoContext, IAppContext {
    active: boolean,
    nextSlide: () => void,
    prevSlide: () => void,
}

interface IState {
    running: boolean,
    paused: boolean,
}


export class SlideshowController extends React.Component<IProps, IState> {

    private timer: any;

    constructor(props: IProps) {
        super(props)
        this.state = {
            running: false,
            paused: false,
        }
        this.tick = this.tick.bind(this)
        this.clearTimer = this.clearTimer.bind(this)
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.pause = this.pause.bind(this)
        this.resume = this.resume.bind(this)
        this.prev = this.prev.bind(this)
        this.next = this.next.bind(this)
    }

    componentDidMount() {
        const {intro, active} = this.props;
        // check if the slideshow should start
        if (!intro && active) {
            this.start();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {

        // console.log(this.props, this.state);

        // check if the slideshow should start
        if (
            // if this section becomes active
            (!prevProps.active && this.props.active)
            || // OR
            // if this section is active and the intro leaves
            (prevProps.intro && !this.props.intro && this.props.active)
        ) {
            this.start()
        }

        // check if the slideshow should stop
        // if this section becomes inactive
        if (prevProps.active && !this.props.active) {
            this.stop()
        }

        // check if the slideshow should pause or resume
        const shouldPause = (
            this.props.nav ||
            this.props.showInfo
        )
        if (
            // the pause property has changed
            (shouldPause !== this.state.paused)
        ) {
            if (shouldPause && !this.state.paused) {
                this.pause()
            }
            if (!shouldPause && this.state.paused) {
                this.resume()
            }
        }

    }

    start() {
        this.setState({
            running: true,
        })
        this.clearTimer()
        this.timer = setInterval(this.tick, autorunTimeout)
    }

    stop() {
        this.setState({
            running: false,
        })
        this.clearTimer()
    }

    pause() {
        this.setState({paused: true})
        this.clearTimer()
    }

    resume() {
        this.setState({paused: false})
        if (this.state.running) {
            this.clearTimer()
            this.timer = window.setTimeout(() => {
                this.tick()
                this.start()
            }, autorunTimeout / 2)
        }
    }

    tick() {
        // console.log(this.props, this.state)
        if (this.state.running && !this.state.paused) {
            const {nextSlide} = this.props;
            nextSlide()
        } else {
            this.clearTimer();
        }
    }

    clearTimer() {
        clearTimeout(this.timer);
        clearInterval(this.timer);
    }

    next() {
        this.stop();
        this.props.nextSlide()
    }

    prev() {
        this.stop();
        this.props.prevSlide()
    }

    render() {
        return (
            <InteractionLayer prevSlide={this.prev} nextSlide={this.next}/>
        )
    }

}