import React from "react";
import {Stage} from "./Stage";
import {CSSTransition} from "react-transition-group";
import {InfoContext} from "../providers/InfoProvider";
import {ContrastObserver} from "./ContrastObserver";
import styles from "./InfoBtn.module.scss"


export class InfoBtn extends React.Component<any, any> {

    private buttonRef = React.createRef<HTMLDivElement>();
    static contextType = InfoContext;


    constructor(props: any) {
        super(props)
        this.buttonRef = React.createRef()
        this.state = {
            hover: false,
            clicked: false,
        }
        this.toggleClicked = this.toggleClicked.bind(this)
        this.toggleHover = this.toggleHover.bind(this)
    }


    toggleHover(nextHover: boolean) {

        const {clicked} = this.state;
        const {setShowInfo} = this.context;

        // if next is off and there is showinfo turn showinfo off
        // else if either nexthover or is clicked is not equal to showinfo then turn showinfo on
        // 00 01 10 11
        setShowInfo(nextHover)


        this.setState({
            hover: nextHover,
            clicked: !nextHover ? false : clicked,
        })
    }


    toggleClicked() {

        const {clicked, hover} = this.state;
        const {setShowInfo} = this.context;
        const nextClicked = !clicked;

        setShowInfo(nextClicked)

        this.setState({
            clicked: nextClicked,
            hover: !nextClicked ? false : hover,
        })

    }


    render() {
        const {hasInfo} = this.context;
        return (
            <ContrastObserver>
                <CSSTransition
                    in={hasInfo}
                    classNames={"fade-1000"}
                    timeout={1000}
                    unmountOnExit
                    mountOnEnter
                    nodeRef={this.buttonRef}
                >
                    <Stage type={"info-btn"}>
                        <div
                            ref={this.buttonRef}
                            className={styles.button}
                            onMouseEnter={() => {
                                this.toggleHover(true)
                            }}
                            onMouseLeave={() => {
                                this.toggleHover(false)
                            }}
                            onClick={() => {
                                this.toggleClicked()
                            }}
                        >
                            Info
                        </div>
                    </Stage>
                </CSSTransition>
            </ContrastObserver>
        )
    }


}