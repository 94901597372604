import React from "react";
import {NavLink, NavLinkProps} from "react-router-dom";
import styles from "./NavigationLink.module.scss";
import {ContrastObserver} from "./ContrastObserver";

export const NavigationLink: React.FC<NavLinkProps> = props => {
    return (
        <ContrastObserver>
            <NavLink {...props} className={styles.link} activeClassName={"active"}>
                {props.children}
            </NavLink>
        </ContrastObserver>
    )
}