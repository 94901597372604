import React, {createContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {InfoBtn} from "../atoms/InfoBtn";

export interface IInfoContext {
    showInfo: boolean,
    setShowInfo: (bool: boolean) => void,
    hasInfo: boolean,
    setHasInfo: (bool: boolean) => void,
}

const defaultContext = {
    showInfo: false,
    setShowInfo: (bool: boolean) => {
    },
    hasInfo: false,
    setHasInfo: (bool: boolean) => {
    },
}

export const InfoContext = createContext<IInfoContext>(defaultContext);

/***
 *      this component is able to toggle the info prop and pass them to the slides
 *      the hasInfo state is always adusted if
 *          A: a slide becomes active
 *          B: the about section becomes active
 *      if the showInfo state ist set to false, and if the autorun is on the slideshow will be toggled.
 ***/
export const InfoProvider: React.FC =
    ({
         children
     }) => {

        const [showInfo, setShowInfo] = useState<boolean>(false);
        const [hasInfo, setHasInfo] = useState<boolean>(false);

        // hide info if location changes
        const location = useLocation();
        useEffect(() => {
            setShowInfo(false)
        }, [location])

        return (
            <InfoContext.Provider
                value={{
                    showInfo,
                    setShowInfo,
                    hasInfo,
                    setHasInfo,
                }}
            >
                {children}
            </InfoContext.Provider>
        )
    }