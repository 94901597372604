import React from 'react';
import "./styles/index.scss"
import "./styles/fade-transision.scss"

import {BrowserRouter as Router} from "react-router-dom";
import {Routing} from "./components/views/Routing";
import {AppProvider} from "./components/providers/AppProvider";
import {BackgroundColorProvider} from "./components/providers/BackgroundProvider";
import {ScreenProvider} from "./components/providers/ScreenProvider";


/*
*
*   TODO:
*    - Google Suche
*    - Klick bei älteren Safari auf prev / next
* * */

function App() {

    return (
        <ScreenProvider>
            <Router>
                <BackgroundColorProvider>
                    <AppProvider>
                        <Routing/>
                    </AppProvider>
                </BackgroundColorProvider>
            </Router>
        </ScreenProvider>
    )
}

export default App;
