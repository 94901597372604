import React, {useContext, useEffect} from "react";
import styles from "./CopyrightSlide.module.scss"
import {CSSTransition} from "react-transition-group";
import {BackgroundColorContext} from "../providers/BackgroundProvider";
import {InfoContext} from "../providers/InfoProvider";


export const CopyrightSlide: React.FC<{
    visible: boolean
}> = ({visible}) => {

    const {setHasInfo,setShowInfo} = useContext(InfoContext)
    const {setBackgroundColor} = useContext(BackgroundColorContext)
    const nodeRef = React.useRef(null)

    useEffect(() => {
        if (visible) {
            setBackgroundColor("#C1C0C0")
            setHasInfo(false);
            setShowInfo(false);
        }
    }, [visible])

    return (
        <CSSTransition
            in={visible}
            timeout={1000}
            classNames={"fade-1000"}
            unmountOnExit
            mountOnEnter
            nodeRef={nodeRef}
        >
            <div
                className={styles.copyright}
                ref={nodeRef}
            >
                © Ruedi Walti Fotografie
            </div>
        </CSSTransition>

    )
}
