import React, {useContext, useEffect, useRef, useState} from "react";
import styles from "./Navigation.module.scss";
import {CSSTransition} from "react-transition-group";
import {withRouter} from "react-router-dom";
import {NavigationBody} from "../molecules/NavigationBody";
import {NavBtn} from "../atoms/NavBtn";
import {AppContext} from "../providers/AppContext";
import {Stage} from "../atoms/Stage";


export const Navigation = withRouter(({location}) => {

        const {nav, setNav} = useContext(AppContext);
        const [preventInteraction, setPreventInteraction] = useState(false);
        const nodeRef = React.useRef(null)

        const openTimer = useRef<number | undefined>(undefined);

        useEffect(() => {
            setNav(false);
        }, [location])

        const handleClick = () => {
            // console.log("click")
            if (!preventInteraction) {
                toggleNav()
            }
        }

        const handleEnter = () => {
            if (!nav && !preventInteraction) {
                setPreventInteraction(true)
                openTimer.current = window.setTimeout(() => {
                    setPreventInteraction(false)
                }, 700)
                toggleNav()
            }
        }


        const toggleNav = () => {
            setNav(!nav)
        }

        return (
            <React.Fragment>

                <Stage type={"nav-btn"}>
                    <div className={styles.button}>
                        <NavBtn
                            onClick={handleClick}
                            onMouseEnter={handleEnter}
                            active={nav}
                        />
                    </div>
                </Stage>

                <CSSTransition
                    in={nav}
                    classNames={"fade-1000"}
                    timeout={1000}
                    mountOnEnter
                    unmountOnExit
                    nodeRef={nodeRef}
                >
                    <Stage type={"nav-body"}>
                        <div className={styles.body} ref={nodeRef}>
                            <NavigationBody/>
                        </div>
                    </Stage>
                </CSSTransition>

            </React.Fragment>
        )
    }
)